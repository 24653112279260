.btn {
    font-size: 1.2rem;
    color: #264653;
    background-color: none;
    background: none;

}
.dropdown-item {
    font-size: 1.2rem;
    color: #264653;
}

.dropdown-list {
    background-color: red;
    display: flex;
    flex-direction: column;
    padding: .2rem;
    padding-left: 1rem;
}